import React from 'react'
import { Provider } from 'react-redux'
import { store } from '../../redux/store'
import { Global, css } from '@emotion/react'
import { theme } from '../../styles/theme'
// import reset from 'react-style-reset'
export interface RootWrapperProps {
  children: React.ReactNode
}

const RootWrapper = ({ children }: RootWrapperProps) => {
  return (
    <Provider store={store}>
      <Global
        styles={css({
          '@font-face': {
            fontFamily: 'Amalfi Coast',
            src: 'url("../fonts/amalfi-coast.woff")',
          },
          '*': {
            boxSizing: 'border-box',
          },
          'html,button,input': {
            fontFamily: 'Poppins',
            fontSize: '1rem',
            color: theme.colors.text.body,
          },
          body: {
            background: theme.colors.background.blue,
            padding: 0,
            margin: 0,
          },
          '::-webkit-scrollbar': {
            display: 'none',
          },
          '*,:after:before': {
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
          },
          button: {
            backgroundColor: 'transparent',
            outline: 0,
            border: 0,
          },
          p: {
            padding: 0,
            margin: 0,
          },
          input: {
            '::-webkit-outer-spin-button,::-webkit-inner-spin-button ': {
              WebkitAppearance: 'none',
              margin: 0,
            },
          },
          main: {
            overflowY: 'hidden',
            height: '100%',
            cursor: 'default',
          },
        })}
      />
      {children}
    </Provider>
  )
}

export default RootWrapper
