module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","display":"standalone","name":"Mikko Vänskä | Designer Developer","short_name":"Mikko Vänskä","description":"Design and web development portfolio","lang":"en","icon":"src/icons/thumbnail-icon.svg","theme_color":"#0074FD","background_color":"#F6F6F6","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f7dcda9ac9234333693fa0f95b5dce5c"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
