import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ISiteState {
  logoAnimationFinished: boolean
  heroSubtitleVisible: boolean
  heroFinished: boolean
  loggedIn: boolean
  visitorName: string | null
  loginContinue: boolean
}

const initialState: ISiteState = {
  logoAnimationFinished: false,
  heroFinished: false,
  heroSubtitleVisible: false,
  loggedIn: false,
  visitorName: null,
  loginContinue: false,
}

export const site = createSlice({
  name: 'site',
  initialState,
  reducers: {
    setLogoAnimation: (state, action: PayloadAction<boolean>) => {
      state.logoAnimationFinished = action.payload
    },
    setHeroSubtitleVisible: (state, action: PayloadAction<boolean>) => {
      state.heroSubtitleVisible = action.payload
    },
    setHeroFinished: (state, action: PayloadAction<boolean>) => {
      state.heroFinished = action.payload
    },
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload
    },
    setVisitorName: (state, action: PayloadAction<string>) => {
      state.loggedIn = true
      state.visitorName = action.payload
    },
    setLoginContinue: (state, action: PayloadAction<boolean>) => {
      state.loginContinue = action.payload
    },
  },
})

export const {
  setLogoAnimation,
  setHeroSubtitleVisible,
  setHeroFinished,
  setLoggedIn,
  setVisitorName,
  setLoginContinue,
} = site.actions

export default site.reducer
