const orange = '#F2994A'
const blue = '#020f20'

const magenta = '#D45985'
const purple = '#7A80FF'
const yellow = '#F5D151'
const darkBlue = '#0075FF'

export interface IBreakpoints {
  xs: 480
  s: 768
  m: 992
  l: 1200
  xl: 1800
}

const breakpoints: IBreakpoints = {
  xs: 480,
  s: 768,
  m: 992,
  l: 1200,
  xl: 1800,
}

export const theme = {
  breakpoints: breakpoints,
  colors: {
    magenta: magenta,
    purple: purple,
    yellow: yellow,
    orange: orange,
    darkBlue: darkBlue,
    background: {
      blue: '#020f20',
      gray: '#F7F7F7',
    },
    spiral: {
      primary: '#F47E28',
      secondary: '#F47E28',
    },
    gradient: {
      blue: `linear-gradient(180deg, #001F3D 0%, #000F1F 100%)`,
      primary: `linear-gradient(180deg, ${magenta} 0%,${purple} 50%, ${yellow} 100%)`,
      orange: `linear-gradient(180deg, #F3B456 0%, #F87C47 100%)`,
    },
    text: {
      title: '#FFFFFF',
      body: '#CCCFD2',
      secondary: '#545454',
      gradient: `-webkit-linear-gradient(180deg, ${magenta} 0%,${purple} 50%, ${yellow} 100%)`,
      dark: {
        primary: blue,
        secondary: '#313E4B',
        body: '#313E4B',
      },
      light: {
        primary: 'hsl(0deg 0% 80%)',
        secondary: 'hsl(0deg 0% 60%)',
        body: 'hsl(0deg 0% 60%)',
      },
    },
    border: {
      input: '#C9C9C9',
    },
    shadow: {
      darkCardOnWhite: `0px 4px 8px 0 rgba(0, 16, 32, 0.25)`,
    },
  },
  text: {
    fontSize: {
      s: '.75rem',
      m: '1.125rem',
      l: '1.25rem',
      xl: '2rem',
    },
  },
  border: {
    radius: '.5rem',
  },
  space: ['0.25rem', '0.5rem', '1rem', '2rem'],
}
